import styled, { css } from "styled-components";

const APPEARANCES = {
  primary: {
    color: "rgb(24, 121, 78)",
    backgroundColor: "rgb(221, 243, 228)",
    backgroundHoverColor: "rgb(204, 235, 215)",
    backgroundActiveColor: "rgb(221, 243, 228)",
    shadowColor: "rgb(146 206 172)",
  },
  secondary: {
    color: "#eee",
    backgroundColor: "#f6675b",
    backgroundHoverColor: "#f97c72",
    backgroundActiveColor: "#f7675c",
    shadowColor: "#f9867c",
  },
  tertiary: {
    color: "",
    backgroundColor: "",
    backgroundHoverColor: "",
    shadowColor: "",
  },
};
export const ButtonStyled = styled.button`
  all: unset;
  display: block;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  /* height: 35px; */

  font-size: ${({ buttonSize }) => (buttonSize === "big" ? "20px" : "16px")};
  padding: ${({ buttonSize }) =>
    buttonSize === "big" ? "15px 20px" : "10px 15px"};

  transition: all 0.5s ease;
  margin-left: auto;
  font-family: -apple-system, system-ui, sans-serif !important;
  background-color: ${({ appearance }) =>
    APPEARANCES[appearance]?.backgroundColor};
  color: ${({ appearance }) => APPEARANCES[appearance]?.color};

  &:hover {
    background-color: ${({ appearance }) =>
      APPEARANCES[appearance]?.backgroundHoverColor};
  }
  &:focus {
    box-shadow: ${({ appearance }) =>
      `${APPEARANCES[appearance]?.shadowColor} 0px 0px 0px 2px`};
  }
  &:active {
    background-color: ${({ appearance }) =>
      APPEARANCES[appearance]?.backgroundActiveColor};
  }
`;
