import React from "react";
import Markdown from "markdown-to-jsx";

import {
  PeritajeStyles,
  ImageContainer,
  Info,
  ServiceContainer,
} from "./Service.styled";

import { graphql, Link } from "gatsby";
import Button from "../../components/UI/Button";
import SEO from "../../components/SEO";

export default function Service(data) {
  const service = data.data.service;

  return (
    <>
      <SEO title={service.title.es} />
      <PeritajeStyles className="section">
        <div className="container">
          <Info>
            <Markdown
              options={{
                overrides: {
                  span: {
                    component: ({ children, ...props }) => (
                      <p {...props}>{children}</p>
                    ),
                  },
                },
              }}
            >
              {service.description.es}
            </Markdown>
          </Info>

          {service.subservices && (
            <ul>
              {service.subservices.map((subservice) => {
                return (
                  <li key={subservice.title.es}>
                    <ImageContainer
                      image={subservice.image?.asset.url}
                    ></ImageContainer>
                    <ServiceContainer>
                      <h2>{subservice.title?.es}</h2>
                      {subservice.description?.es && (
                        <Markdown
                          options={{
                            overrides: {
                              span: {
                                component: ({ children, ...props }) => (
                                  <p {...props}>{children}</p>
                                ),
                              },
                            },
                          }}
                        >
                          {subservice?.description?.es}
                        </Markdown>
                      )}
                    </ServiceContainer>
                    <Link to="/#contact">
                      <Button text="Contacto" size="big" />
                    </Link>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      </PeritajeStyles>
    </>
  );
}

export const query = graphql`
  query ($slug: String!) {
    service: sanityService(slug: { current: { eq: $slug } }) {
      title {
        es
      }
      subtitle {
        es
      }
      description {
        es
      }
      id
      subservices {
        title {
          es
        }
        description {
          es
        }
        image {
          asset {
            url
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
      image {
        asset {
          url
          fluid(maxWidth: 400) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
