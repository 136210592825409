import styled from "styled-components";
export const PeritajeStyles = styled.section`
  color: #303030;
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  h2 {
    font-size: 2.3rem;
    margin: 0 0 1.5rem;
    position: relative;
    line-height: 1.2;
    font-weight: 600;
    color: #303030;
    color: var(--color__secondary-dark);
  }

  p {
    font-size: 1.2rem;
    margin-top: 1rem;
    line-height: 1.5;
    color: #303030;
    color: var(--color__dark);
  }

  button {
    /* background-color: #e2b33c; */
    align-self: center;
    @media (max-width: 1127px) {
      margin: 0 auto;
    }
  }

  a {
    display: block;
    align-self: center;
    @media (max-width: 1127px) {
      margin: 0 auto;
    }
  }
  > div {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
  }
  ul {
    margin-top: 84px;
    display: flex;
    flex-direction: column;

    li {
      display: flex;
      gap: 24px;
      margin-bottom: 8rem;
      padding: 24px;

      flex-direction: column;
      @media (min-width: 1128px) {
        flex-direction: row;
        gap: 4rem;
      }
      @media (max-width: 1127px) {
        text-align: center;
      }
    }
  }
`;

export const ImageContainer = styled.div`
  height: 200px;
  width: 200px;
  border-radius: 3%;
  flex-shrink: 0;
  align-self: center;
  background-image: ${({ image }) => `url(${image})`};
  background-size: cover;
  background-position: center;
`;

export const Info = styled.div`
  padding: 36px;
  background-color: #ddf2e46b;
  border-radius: 4px;
`;

export const ServiceContainer = styled.div`
  flex-grow: 1;
`;
